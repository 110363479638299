.space_listing_section {
    padding: 60px 105px 60px 105px;
    background: #F4F4F4;
}
.visit_listing_section {
    padding: 0px 105px 60px 105px;
    background: #F4F4F4;
}

.space_listing_section .nav_link_cards {
    justify-content: left;
    display: flex;
}

.space_listing_section .nav_link_cards .project_card {
    width: calc(100% / 3);
    padding: 0 15px;
    margin-bottom: 30px;
}

.space_listing_section .nav_link_cards .project_card .card_box {
    width: 100%;
}

.space_listing_section .nav_link_cards .project_card .card_box .card_image img {
    width: 100%;
    /* height: 100%; */
}

.space_listing_section .nav_link_cards .project_card .card_box .card_content {
    background: white;
    padding: 30px;
    position: relative;
    /* min-height: 365px; */
}

.space_listing_section .nav_link_cards .project_card .card_box .card_content .card_heading {
    font-size: 25px;
    /* font-weight: 700; */
    /* padding-bottom: 30px; */
    font-family: kohinoorsemibold;
}

.space_listing_section .nav_link_cards .project_card .card_box .card_content .design_section {
    width: 15px;
    height: 15px;
    position: absolute;
    bottom: 0;
    right: 0;
    border-top-left-radius: 100%;
    background-color: #ffca05;
}

@media (max-width : 1299px) {
    .space_listing_section .nav_link_cards .project_card .card_box .card_content .card_heading {
        font-size: 20px;
    }
}

@media (max-width : 1199px) {
    .space_listing_section {
        padding: 60px 75px 90px 75px;
    }
    .visit_listing_section {
        padding: 0px 75px 90px 75px;
    }
}

@media (max-width : 1024px) {
    .space_listing_section {
        padding: 60px 30px 60px 30px;
    }
    .visit_listing_section {
        padding: 0px 30px 60px 30px;
    }

    .space_listing_section .nav_link_cards .project_card .card_box .card_content .card_heading {
        font-size: 18px;
    }

    .space_listing_section .nav_link_cards .project_card .card_box .card_content {
        padding: 20px;
    }
}

@media (max-width : 525px) {
    .space_listing_section .nav_link_cards {
        display: block;
    }
    .space_listing_section .nav_link_cards .project_card {
        width : 100%;
        padding: 0;
    }
    .space_listing_section {
        padding : 30px 30px 0 30px;
    }
    .visit_listing_section {
        padding : 0px 15px 0 15px;
    }
    .space_listing_section .nav_link_cards .project_card:last-child {
        margin-bottom : 0;
    }
}