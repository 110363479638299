.oneCls-1 {
  fill: #58595b;
}

.oneCls-1,
.oneCls-2,
.oneCls-3,
.oneCls-4,
.oneCls-5,
.oneCls-6,
.oneCls-7,
.oneCls-8,
.oneCls-9,
.oneCls-10,
.oneCls-11,
.oneCls-12,
.oneCls-13,
.oneCls-14 {
  stroke-width: 0px;
}

.oneCls-15 {
  stroke-width: 1.41px;
}

.oneCls-15,
.oneCls-16,
.oneCls-17,
.oneCls-18,
.oneCls-19,
.oneCls-20,
.oneCls-21,
.oneCls-22,
.oneCls-5,
.oneCls-23,
.oneCls-24,
.oneCls-25,
.oneCls-26,
.oneCls-27,
.oneCls-28,
.oneCls-29,
.oneCls-30,
.oneCls-31,
.oneCls-32,
.oneCls-33,
.oneCls-34,
.oneCls-35,
.oneCls-36,
.oneCls-37,
.oneCls-38,
.oneCls-39,
.oneCls-40 {
  fill: none;
}

.oneCls-15,
.oneCls-16,
.oneCls-17,
.oneCls-18,
.oneCls-19,
.oneCls-20,
.oneCls-21,
.oneCls-22,
.oneCls-23,
.oneCls-24,
.oneCls-25,
.oneCls-26,
.oneCls-27,
.oneCls-28,
.oneCls-29,
.oneCls-30,
.oneCls-32,
.oneCls-33,
.oneCls-34,
.oneCls-35,
.oneCls-36,
.oneCls-37,
.oneCls-39,
.oneCls-40 {
  stroke-miterlimit: 22.93;
}

.oneCls-15,
.oneCls-18,
.oneCls-19,
.oneCls-20,
.oneCls-22,
.oneCls-23,
.oneCls-24,
.oneCls-29,
.oneCls-30,
.oneCls-32,
.oneCls-33,
.oneCls-35,
.oneCls-36,
.oneCls-39,
.oneCls-40 {
  stroke: #d9dada;
}

.oneCls-16 {
  stroke: #e31f26;
  stroke-width: 4.24px;
}

.oneCls-17 {
  stroke: #dc2126;
}

.oneCls-17,
.oneCls-25,
.oneCls-26,
.oneCls-28,
.oneCls-37 {
  stroke-width: 5px;
}

.oneCls-18 {
  stroke-width: 1.25px;
}

.oneCls-19 {
  stroke-width: 1.86px;
}

.oneCls-2,
.oneCls-11 {
  fill: #5b5b5b;
}

.oneCls-3 {
  fill: #fecc00;
}

.oneCls-3,
.oneCls-7,
.oneCls-8,
.oneCls-10,
.oneCls-11,
.oneCls-13,
.oneCls-14 {
  fill-rule: evenodd;
}

.oneCls-20 {
  stroke-width: 1.24px;
}

.oneCls-21 {
  stroke-width: 2.92px;
}

.oneCls-21,
.oneCls-28 {
  stroke: #2d2556;
}

.oneCls-4,
.oneCls-10 {
  fill: #f4f4f4;
}

.oneCls-22 {
  stroke-width: 1.85px;
}

.oneCls-5 {
  clip-rule: evenodd;
}

.oneCls-23 {
  stroke-width: 1.84px;
}

.oneCls-24 {
  stroke-width: 1.3px;
}

.oneCls-25 {
  stroke: #ec691f;
}

.oneCls-6,
.oneCls-8 {
  fill: #dc2126;
}

.oneCls-26 {
  stroke: #fecc00;
}

.oneCls-7 {
  fill: #418b37;
}

.oneCls-27 {
  stroke: #43bcea;
  stroke-width: 3.79px;
}

.oneCls-9 {
  fill: #767475;
}

.oneCls-41 {
  clip-path: url(#clippath);
}

.oneCls-29 {
  stroke-width: 1.96px;
}

.oneCls-30 {
  stroke-width: 1.24px;
}

.oneCls-31 {
  stroke-width: .62px;
}

.oneCls-31,
.oneCls-38 {
  stroke: #5b5b5b;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.oneCls-32 {
  stroke-width: 1.23px;
}

.oneCls-33 {
  stroke-width: 1.85px;
}

.oneCls-34 {
  stroke: #418b37;
  stroke-width: 4px;
}

.oneCls-35 {
  stroke-width: 1.25px;
}

.oneCls-36 {
  stroke-width: 1.23px;
}

.oneCls-12,
.oneCls-13 {
  fill: #727271;
}

.oneCls-37 {
  stroke: #0086b3;
}

.oneCls-38 {
  stroke-width: .42px;
}

.oneCls-14 {
  fill: #ec691f;
}

.oneCls-39 {
  stroke-width: 1.24px;
}

.oneCls-40 {
  stroke-width: 1.96px;
}

/* ---------------------------------------------------------- */
.two_st0 {
  stroke: #5b5b5b;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: .62px;
}

.two_st0,
.two_st1,
.two_st2,
.two_st3,
.two_st4,
.two_st5,
.two_st6,
.two_st7 {
  fill: none;
}

.two_st8,
.two_st9,
.two_st10,
.two_st11,
.two_st12,
.two_st13,
.two_st14 {
  fill-rule: evenodd;
}

.two_st8,
.two_st15 {
  fill: #f4f4f4;
}

.two_st16 {
  fill: #767475;
}

.two_st9 {
  fill: #418b37;
}

.two_st10 {
  fill: #ec691f;
}

.two_st11,
.two_st17 {
  fill: #dc2126;
}

.two_st1 {
  stroke: #418b37;
  stroke-width: 4px;
}

.two_st1,
.two_st2,
.two_st3,
.two_st4,
.two_st5,
.two_st6,
.two_st7 {
  stroke-miterlimit: 22.93;
}

.two_st18,
.two_st14 {
  fill: #5b5b5b;
}

.two_st12 {
  fill: #727271;
}

.two_st13 {
  fill: #fecc00;
}

.two_st2 {
  stroke: #fecc00;
}

.two_st2,
.two_st3,
.two_st4,
.two_st5,
.two_st6 {
  stroke-width: 5px;
}

.two_st19 {
  fill: #58595b;
}

.two_st3 {
  stroke: #ec691f;
}

.two_st4 {
  stroke: #898989;
}

.two_st5 {
  stroke: #0086b3;
}

.two_st6 {
  stroke: #dc2126;
}

.two_st7 {
  stroke: #e31f26;
  stroke-width: 4.24px;
}

/* ---------------------------------------------------------- */

.threeCls-1 {
  stroke: #e31f26;
  stroke-width: 4.24px;
}

.threeCls-1,
.threeCls-2,
.threeCls-3,
.threeCls-4,
.threeCls-5,
.threeCls-6,
.threeCls-7,
.threeCls-8 {
  fill: none;
}

.threeCls-1,
.threeCls-2,
.threeCls-3,
.threeCls-4,
.threeCls-5,
.threeCls-7,
.threeCls-8 {
  stroke-miterlimit: 22.93;
}

.threeCls-9,
.threeCls-10,
.threeCls-11,
.threeCls-12,
.threeCls-13,
.threeCls-14,
.threeCls-15,
.threeCls-16 {
  stroke-width: 0px;
}

.threeCls-9,
.threeCls-15 {
  fill: #5b5b5b;
}

.threeCls-10,
.threeCls-14 {
  fill: #f4f4f4;
}

.threeCls-2 {
  stroke: #ec691f;
}

.threeCls-2,
.threeCls-3,
.threeCls-5,
.threeCls-8 {
  stroke-width: 5px;
}

.threeCls-11 {
  fill: #dc2126;
}

.threeCls-3,
.threeCls-4 {
  stroke: #fecc00;
}

.threeCls-12 {
  fill: #418b37;
}

.threeCls-12,
.threeCls-14,
.threeCls-15 {
  fill-rule: evenodd;
}

.threeCls-4 {
  stroke-width: 2.55px;
}

.threeCls-13 {
  fill: #767475;
}

.threeCls-5 {
  stroke: #f37021;
}

.threeCls-6 {
  stroke: #5b5b5b;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: .62px;
}

.threeCls-7 {
  stroke: #418b37;
  stroke-width: 4px;
}

.threeCls-16 {
  fill: #727271;
}

.threeCls-8 {
  stroke: #0086b3;
}

/* ---------------------------------------------------------- */
.four_st0 {
  stroke: #5b5b5b;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: .62px;
}

.four_st0,
.four_st1,
.four_st2,
.four_st3,
.four_st4,
.four_st5,
.four_st6,
.four_st7,
.four_st8 {
  fill: none;
}

.four_st9,
.four_st10,
.four_st11 {
  fill-rule: evenodd;
}

.four_st9,
.four_st12 {
  fill: #f4f4f4;
}

.four_st13 {
  fill: #6e6f71;
}

.four_st14 {
  fill: #767475;
}

.four_st1 {
  stroke: #f37021;
}

.four_st1,
.four_st2,
.four_st3,
.four_st5,
.four_st6,
.four_st7,
.four_st8 {
  stroke-miterlimit: 22.93;
}

.four_st1,
.four_st4,
.four_st5,
.four_st6,
.four_st7 {
  stroke-width: 5px;
}

.four_st10 {
  fill: #418b37;
}

.four_st2 {
  stroke: #418b37;
  stroke-width: 4px;
}

.four_st3 {
  stroke-width: 2.55px;
}

.four_st3,
.four_st5 {
  stroke: #fecc00;
}

.four_st15,
.four_st11 {
  fill: #5b5b5b;
}

.four_st4 {
  stroke: #f26f21;
  stroke-miterlimit: 22.93;
}

.four_st16 {
  fill: #58595b;
}

.four_st6 {
  stroke: #ec691f;
}

.four_st7 {
  stroke: #0086b3;
}

.four_st17 {
  fill: #dc2126;
}

.four_st8 {
  stroke: #e31f26;
  stroke-width: 4.24px;
}

/* ---------------------------------------------------------- */

.fiveCls-1 {
  stroke: #e31f26;
  stroke-width: 4.24px;
}

.fiveCls-1,
.fiveCls-2,
.fiveCls-3,
.fiveCls-4,
.fiveCls-5,
.fiveCls-6,
.fiveCls-7,
.fiveCls-8 {
  fill: none;
}

.fiveCls-1,
.fiveCls-3,
.fiveCls-4,
.fiveCls-5,
.fiveCls-7,
.fiveCls-8 {
  stroke-miterlimit: 22.93;
}

.fiveCls-9,
.fiveCls-10,
.fiveCls-2,
.fiveCls-11,
.fiveCls-12,
.fiveCls-13,
.fiveCls-14,
.fiveCls-15 {
  stroke-width: 0px;
}

.fiveCls-9,
.fiveCls-15 {
  fill: #5b5b5b;
}

.fiveCls-10,
.fiveCls-14 {
  fill: #f4f4f4;
}

.fiveCls-3 {
  stroke: #ec691f;
}

.fiveCls-3,
.fiveCls-4,
.fiveCls-5,
.fiveCls-8 {
  stroke-width: 5px;
}

.fiveCls-11 {
  fill: #dc2126;
}

.fiveCls-4 {
  stroke: #fecc00;
}

.fiveCls-12 {
  fill: #418b37;
}

.fiveCls-12,
.fiveCls-14,
.fiveCls-15 {
  fill-rule: evenodd;
}

.fiveCls-13 {
  fill: #767475;
}

.fiveCls-5 {
  stroke: #f37021;
}

.fiveCls-6 {
  stroke: #5b5b5b;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: .62px;
}

.fiveCls-7 {
  stroke: #418b37;
  stroke-width: 4px;
}

.fiveCls-8 {
  stroke: #0086b3;
}


/* ---------------------------------------------------------- */

.sixCls-1 {
  stroke: #e31f26;
  stroke-width: 4.24px;
}

.sixCls-1,
.sixCls-2,
.sixCls-3,
.sixCls-4,
.sixCls-5,
.sixCls-6,
.sixCls-7,
.sixCls-8,
.sixCls-9 {
  fill: none;
}

.sixCls-1,
.sixCls-3,
.sixCls-4,
.sixCls-5,
.sixCls-6,
.sixCls-8,
.sixCls-9 {
  stroke-miterlimit: 22.93;
}

.sixCls-10,
.sixCls-11,
.sixCls-2,
.sixCls-12,
.sixCls-13,
.sixCls-14,
.sixCls-15,
.sixCls-16 {
  stroke-width: 0px;
}

.sixCls-10,
.sixCls-16 {
  fill: #5b5b5b;
}

.sixCls-11,
.sixCls-15 {
  fill: #f4f4f4;
}

.sixCls-3 {
  stroke: #ec691f;
}

.sixCls-3,
.sixCls-4,
.sixCls-6,
.sixCls-9 {
  stroke-width: 5px;
}

.sixCls-12 {
  fill: #dc2126;
}

.sixCls-4,
.sixCls-5 {
  stroke: #fecc00;
}

.sixCls-13 {
  fill: #418b37;
}

.sixCls-13,
.sixCls-15,
.sixCls-16 {
  fill-rule: evenodd;
}

.sixCls-5 {
  stroke-width: 2.55px;
}

.sixCls-14 {
  fill: #767475;
}

.sixCls-6 {
  stroke: #f37021;
}

.sixCls-7 {
  stroke: #5b5b5b;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: .62px;
}

.sixCls-8 {
  stroke: #418b37;
  stroke-width: 4px;
}

.sixCls-9 {
  stroke: #0086b3;
}


/* ---------------------------------------------------------- */
/* hover */

.highlight:hover .oneCls-4 {
  fill: rgb(255, 255, 255);
}

.highlight:hover .twoCls-4 {
  fill: rgb(255, 255, 255);
}

.highlight:hover .threeCls-10 {
  fill: rgb(255, 255, 255);
}

.highlight:hover .fourCls-11 {
  fill: rgb(255, 255, 255);
}

.highlight:hover .fiveCls-10 {
  fill: rgb(255, 255, 255);
}

.highlight:hover .sixCls-11 {
  fill: rgb(255, 255, 255);
}

.highlight:hover .four_st12 {
  fill: rgb(255, 255, 255);
}

.highlight:hover .two_st15 {
  fill: rgb(255, 255, 255);
}

/* ---------------------------------------------------------- */

