.footerSection {
    padding : 0 120px 15px 120px;
    font-family : kohinoorregular;
    font-size : 20px;
    color : #6E6E6E;
    background : #F4F4F4;
}
.main_row {
    padding-bottom : 15px;
}
.copyright-row {
    padding-top: 45px; 
}
.footer_background {
    background-image: url('https://www.dharohar.org/Footer-bg.d0d90acba0ce1fd1.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
}
.absolute_div {
    position: absolute;
    height: 70%;
    width: 9%;
    left: 53%;
    top: 55%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}
@media (max-width : 1199px) {
    .footerSection {
        padding: 0 90px 15px 90px;
    }
}
@media (max-width : 1024px) {
    .footerSection {
        padding: 0 45px 15px 45px;
        font-size: 18px;
    }
}
@media (max-width : 525px) {
    .footerSection {
        display: none;
    }
    .footerIcons{
        height: 24px;
    }
    .d-flex.justify-content-between {
        padding: 3px;
    }
}
@media (max-width:375px) {
    .d-flex.justify-content-between {
        padding: 2px;
    }
    
}
@media (max-width:320px) {
    .d-flex.justify-content-between {
        padding: 1px;
    }
    
}
a {
    color : unset!important;
    text-decoration: none!important;
  }