.opening_template_card_box {
    background-color: white;
    padding: 30px;
    min-height: 230px;
    position: relative;
}

.opening_template_card_box h2 {
    font-size: 20px;
    font-family: kohinoorsemibold;
    margin-bottom: 20px !important;
}

.opening_template_card_box p {
    font-size: 15px;
    font-family: kohinoorregular;
    color: #6E6E6E;
    margin-bottom: 10px !important;
}

.design_section {
    width: 15px;
    height: 15px;
    position: absolute;
    bottom: 0;
    right: 0;
    border-top-left-radius: 100%;
}