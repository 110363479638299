.diamond-container {
  position: relative;
  /* transition: transform 0.4s ease-in-out;  */
 
  margin-left: 7vw;
  height: auto;
  width: auto;

  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
}

.gif-container1 {
  position: absolute;
  left: 28%;
    top: -12%;
  animation: gif-container1-13 4s ease-out ;
  cursor: pointer;
}

.gif-container2 {
  position: absolute;
  left: 17%;
    top: 47%;
  animation: gif-container2-11 4s ease-out ;
  cursor: pointer;
}

.gif-container11 {
  position: absolute;
  left: 2%;
    
    top: 65%;
  animation: gif-container2-11 4s ease-out  ;
}

.gif-container3 {
  position: absolute;
  left: 45%;
    top: 30%;
  animation: gif-container3 4s ease-out ;
}

.gif-container4 {
  position: absolute;
  top: 132%;
    left: 14%;
    
  animation: gif-container4-5-6 4s ease-out  ;
}

.gif-container5 {
  position: absolute;
  top: 171%;
    left: 21%;
  animation: gif-container4-5-6 4s  ease-out ;
  cursor: pointer;
}

.gif-container6 {
  position: absolute;
  top: 156%;
  left: 29%;
  animation: gif-container4-5-6 4s ease-out 3s ease;
  cursor: pointer;
}

.gif-container7 {
  position: absolute;
  top: 207%;
  left: 32%;
  animation: gif-container7 4s ease-out ;
  cursor: pointer;
}

.gif-container8 {
  position: absolute;
  top: 167%;
  left: 46%;
  animation: gif-container8 4s ease-out ;
  cursor: pointer;
}
.gif-container9 {
  position: absolute;
  top: 135%;
    left: 55%;
  animation: gif-container9 4s ease-out ;
  cursor: pointer;
}

.gif-container12 {
  position: absolute;
  top: 108%;
  left: 64%;
  animation: gif-container12 4s ease-out ;
}

.gif-container10 {
  top: 94%;
  left: 135%;

  position: inherit;
  animation: zoomInOut 4s ease-out ;
}
.gif-container13 {
  position: absolute;
  left: 43.9% !important;
    top: 32% !important;
  animation: gif-container1-13 4s ease-out ;
  cursor: pointer;
}

@keyframes zoomInOut {
  0% {
    transform: scale(0.2);
  }

  25% {
    transform: scale(1);
  }
}

@keyframes gif-container1-13 {
  0% {
    transform: translateY(-80px);
  }
  25% {
    transform: translateY(0);
  }
}
@keyframes gif-container2-11 {
  0% {
    transform: translateX(-80px);
  }
  25% {
    transform: translateX(0);
  }
}
@keyframes gif-container4-5-6 {
  0% {
    transform: translateX(-80px) translateY(80px);
  }
  25% {
    transform: translateX(0) translateY(0px);
  }
}
@keyframes gif-container7 {
0% {
    transform: translateY(80px);
  }
  25% {
    transform: translateY(0);
  }
}
@keyframes gif-container8 {
  0% {
    transform: translateY(80px) translateX(25px);
  }
  25% {
    transform: translateY(0) translateX(0);
  }
}
@keyframes gif-container3 {
  0% {
    transform: translateX(80px) translateY(-80px);
  }
  25% {
    transform: translateX(0) translateY(0px);
  }
}
@keyframes gif-container12 {
  0% {
    transform: translateX(80px);
  }
  25% {
    transform: translateX(0);
  }
}
@keyframes gif-container9 {
  0% {
    transform: translateX(80px) translateY(80px);
  }
  25% {
    transform: translateX(0) translateY(0px);
  }
} 

 @media (min-width:1441px) {
  /* .gif-container13 {
    position: absolute;
    left: 46% !important;
    top: 24% !important;
   
  } */
  .gif-container2{
    position: absolute;
    top: 48%;
    left: 18%;
  }
 }


 @media (min-width: 1440px){
  .gif-container1{
    position: absolute;
    top: -20%;
    left: 30%;
}
.gif-container2{
  position: absolute;
  left: 19%;
    top: 39%;
}
   .gif-container13{
     
      position: absolute;
      left: 45.2% !important;
        top: 24% !important;
     
    }
    
   
   
   .gif-container11{
    position: absolute;
    top: 61%;
    left: 4%;
   }
   .gif-container3{
    position: absolute;
    top: 25%;
    left: 47%;
   }
   .gif-container4{
    position: absolute;
    left: 17%;
    top: 127%;
   }
   .gif-container5{
    position: absolute;
    top: 163%;
    left: 23%;
   }
   .gif-container6{
    position: absolute;
    top: 148%;
    left: 30.4%;
   }
   .gif-container7{
    position: absolute;
    top: 198%;
    left: 34%;
    
   } 
   .gif-container10
   {
    position: inherit;
    top: 89%;
    left: 150%;
   
   }
   
 .gif-container8{
    position: absolute;
    top: 159%;
    left: 47%;
    
   }
   .gif-container9{
    position: absolute;
    top: 128%;
    left: 56%;
   }
  
   
.gif-container12{
    position: absolute;
    top: 101.7%;
    left: 65%;
    
} 
 }
 @media (max-width: 1024px){
  
  .gif-container1{
    position: absolute ; 
    top: -20% !important;
    left: 30% !important;
}
.gif-container2{
  position: absolute ;
  left: 19% !important;
    top: 42% !important;
}
   .gif-container13{
    position: absolute;
    top: 24% !important;
    left: 44.3% !important;
   
   }
   .gif-container11{
    position: absolute;
    top: 60% !important;
    left: 6% !important;
   }
   .gif-container3{
    position: absolute;
    top: 23% !important;
    left: 45% !important;
   }
   .gif-container4{
    position: absolute;
    left: 17.5% !important;
    top: 124.5% !important;
   }
   .gif-container5{
    position: absolute;
    top: 162% !important;
    left: 23% !important;
   }
   .gif-container6{
    position: absolute;
    top: 147% !important;
    left: 30% !important;
   }
   .gif-container7{
    position: absolute;
    top: 199% !important;
    left: 33% !important;
    
   } 
   .gif-container10
   {
    position: inherit;
    top: 86% !important;
    left: 153% !important;
   
   }
   
 .gif-container8{
    position: absolute;
    top: 159% !important;
    left: 45% !important;
    
   }
   .gif-container9{
    position: absolute;
    top: 127% !important;
    left: 53.3% !important;
   }
  
   
.gif-container12{
    position: absolute;
    top: 101% !important;
    left: 62% !important;
}
}  

@media (max-width: 768px){
   
    
    .gif-container13{
        position: absolute;
        top: 24% !important;
        left: 45% !important;
    }
    .gif-container3{
      position: absolute;
      top: 20% !important;
      left: 45% !important;
     }
     
} 

